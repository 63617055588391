// Make contents nav sticky

(function() {
	var pageNav = $('nav.page-nav');
	var waypoints = pageNav.waypoint({
		handler: function() {
			pageNav.toggleClass('sticky');
		}
	})
})();

