// Add images to some navs

(function() {
	var thumbnailNavLinks = $('nav.with-thumbnails a');
	function setThumbnailNavImages() {
		var windowWidth = $(window).width();
		if (windowWidth > 768) {
			thumbnailNavLinks.each(function() {
				var self = $(this);
				var image = self.data('image');
				if (image) {
					self.css('background-image', 'url(' + image + ')');
				};
			});
		} else {
			thumbnailNavLinks.css('background-image', 'none');
		}
	};
	setThumbnailNavImages();
	$(window).resize(function() {
		setThumbnailNavImages();
	});
})();