// Main nav js file

(function(){
	var globalNavToggle = $('#global-nav-toggle');
	var globalNav = $('#global-nav');
	var globalNavLinks = $('#global-nav a');
	globalNavToggle.on('click', function() {
		globalNavToggle.toggleClass('active');
		globalNav.slideToggle(200);
	});
	// Set current class while in events section
	if(window.location.href.indexOf('/events/') > -1) {
	   $('#global-nav a[href="/events"]').addClass('current');
    }
})();
