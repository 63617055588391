// JS for tiles on homescreen


(function(){
	var isTouchDevice = 'ontouchstart' in document.documentElement;
	if(isTouchDevice) {
		$('html').addClass('touch-device')
	} else {
		$('html').addClass('non-touch-device')
	}
})();