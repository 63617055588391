// Make links to external sites open in a new tab and add a class to them.

(function() {
	$('a').each(function() {
		var internalLink = new RegExp('/' + window.location.host + '/');
		var mailtoLink = new RegExp('^mailto:|^tel:');
		if(!internalLink.test(this.href) && !mailtoLink.test(this.href)) { // If NOT internal link & if NOT mailto link
			var externalLink = $(this);
			externalLink.attr('target', '_blank');
			externalLink.addClass('external-link');
		}
	});
	// Add PDF class to links that are for PDFs
	var docLink = $('a.document-download');
	var docLinkHref = docLink.attr('href');
	if (docLink.length && docLinkHref.match(".pdf$")) {
		docLink.addClass('pdf');
	};
})();