// JS for forms


(function(){
	if ( Modernizr.inputtypes.date === false ) {
		$('input[type="date"], .date input').datepicker();
	};
	$('form .error input').on('blur', function() {
		console.log( $(this).parent('.error') );
		$(this).closest('.error').removeClass('error');
	});
	$('form .error select').on('blur', function() {
		console.log( $(this).parent('.error') );
		$(this).closest('.error').removeClass('error');
	});
	$('form .error textarea').on('blur', function() {
		console.log( $(this).parent('.error') );
		$(this).closest('.error').removeClass('error');
	});
})();
