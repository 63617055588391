// <div class="image-container">
//     <img src="http://lorempixel.com/280/280">
// </div>

// Load images in news articles on larger screens
(function() {
	var newsArticles = $('.news-index .news-item');
	function setNewsArticlesImages() {
		var windowWidth = $(window).width();
		if (windowWidth > 768) {
			newsArticles.each(function() {
				var self = $(this);
				var image = self.data('image');
				if (image) { // If image exists
					self.prepend('<div class="image-container"><img src="' + image + '"></div>')
				};
			});
		} else {
			newsArticles.css('background-image', 'none');
		}
	};
	setNewsArticlesImages();
})();
